<template>
	<div class="couponcoll">
		<div class="title">领券中心</div>
		<div class="contents">
			<div v-for="(item,index) in lista.records" :key='index' class="coup">
				<div class="coup_left">
					<div class="coup_lefta">
						<div>
							<span>￥</span>
							<span style="font-size: 24px;">{{item.price}}</span>
						</div>
						<div>满{{item.consumeThreshold}}元可用</div>
					</div>
					<div style="margin-top: 5px;">使用范围：{{item.scopeType!='PORTION_GOODS'?'平台全部商品可用':'平台部分商品可用'}}</div>
					<div style="margin-top: 5px;color: #818181;">有效期：{{item.endTime}}</div>
				</div>
				<div class="coup_right" @click="receives(item.id)">立即领取</div>
			</div>
		</div>
		<div class="dilogs">
			<el-dialog title="领取优惠券" :visible.sync="dialogVisible" width="400px">
			  <div style="text-align: center;">优惠券领取成功，可到我的优惠券查看</div>
			  <span slot="footer" class="dialog-footer">
			    <el-button @click="routs(1)">立即使用</el-button>
			    <el-button type="primary" @click="routs(2)">我的优惠券</el-button>
			  </span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { getcoupons,receive } from "@/request/xie";
	export default{
		data(){
			return{
				lista:[],
				dialogVisible:false,
				parsm:{
					pageNumber:1,
					pageSize:10
				}
			}
		},
		mounted() {
			this.getlist()
		},
		methods:{
			//获取优惠券列表
			async getlist(){
				let res=await getcoupons(this.parsm)
				this.lista=res.result
				//console.log(res)
			},
			//领取优惠券
			receives(id){
				receive(id).then((res)=>{
					//console.log(res)
					if(res.code==200){
						this.$message.success('领取成功')
						this.getlist()
						setTimeout(()=>{
							this.dialogVisible=true
						},1000)
					}
				})
			},
			//我的优惠券
			routs(ind){
				if(ind==2){
					this.$router.push('coupon')
				}else{
					this.$router.push('../OpusMall')
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.couponcoll{
		background-color: #fff;
		.title{
			color: #00A3E0;
			font-size: 18px;
			padding: 25px 0 20px 40px;
			box-sizing: border-box;
			border-bottom: 1px solid #F3F3F3;
		}
		.contents{
			padding: 28px 46px;
			box-sizing: border-box;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-start;
			.coup{
				width: 382px;
				height: 124px;
				display: flex;
				background-image: url('../../../assets/img/xie/blue.png');
				background-size: 100% 100%;
				flex-flow: row;
				justify-content: space-between;
				margin-right: 28px;
				margin-bottom: 22px;
				.coup_left{
					font-size: 14px;
					color: #535353;
					padding-left: 27px;
					padding-top: 20px;
					box-sizing: border-box;
					.coup_lefta{
						display: flex;
						align-items: center;
						div:first-child{
							color: #00A3E0;
							margin-right: 7px;
						}
						div:last-child{
							padding: 3px 9px;
							box-sizing: border-box;
							background-color: #E3F7FF;
							color: #0075AB;
							font-size: 12px;
						}
					}
				}
				.coup_img{
					display: flex;
					flex-flow: column;
					justify-content: center;
				}
				.coup_right{
					cursor: pointer;
					width: 52px;
					color: #fff;
					font-size: 16px;
					display: flex;
					flex-flow: column;
					justify-content: center;
					align-items: center;
					writing-mode: tb-rl;
					letter-spacing:-2px
				}
			}
		}
		::v-deep .dilogs{
			.el-dialog__header{
				padding: 0 0 0 20px;
				height: 50px;
				line-height: 50px;
				box-sizing: border-box;
				border-radius: 4px 4px 0px 0px;
				font-size: 16px;
				color: #535353;
				background-color: #F8F9FB;
				button{
					display: none;
				}
			}
			.el-dialog__body{
				padding: 40px 0 35px 0;
				box-sizing: border-box;
				font-size: 16px;
				color: #535353;
			}
			.el-dialog__footer{
				padding-top: 0;
			}
			.dialog-footer{
				display: inline-block;
				width: 100%;
				display: flex;
				flex-flow: row;
				justify-content: center;
				button{
					width: 100px;
					padding: 12px 0;
					text-align: center;
				}
				button:first-child{
					background-color: #97999B;
					color: #fff;
				}
			}
		}
	}
</style>
